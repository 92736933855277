<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="form-login-v3">
          <p class="title-form-v3">Create a new password</p>
          <b-form action="" @submit.prevent="tryToChangePassword">
            <div class="form-input">
                <b-form-group>
                  <b-form-input
                    id="password-register-v3"
                    v-on:keyup="validatePassword()"
                    v-model.trim="$v.password.$model"
                    :type="passwordState"
                    class="form-control input-box-v3"
                    :class="{'is-invalid': validationStatus($v.password)}"
                    placeholder="Password"></b-form-input>
                    <div class="invalid-feedback">
                      <span v-if="!$v.password.valid" id="password-error" class="required-color error-message-v3"></span>
                      <span v-if="!$v.password.required" id="password-empty" class="required-color error-message-v3">Password cannot be empty.</span>
                      <span v-if="!$v.password.maxLength" id="password-max-length" class="required-color error-message-v3">Password max. length is 128 characters.</span>
                    </div>
                  <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
                  <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
                  <div class="row">
                    <div class="col-6 required-password">
                      <ul>
                        <li class="marker-grey" id="lower-char">One lowercase letter</li>
                        <li class="marker-grey" id="upper-char">One uppercase letter</li>
                        <li class="marker-grey" id="number-char">One number</li>
                      </ul>
                    </div>
                    <div class="col-6 required-password">
                      <ul>
                        <li class="marker-grey" id="special-char">One special character</li>
                        <li class="marker-grey" id="minimum-character">8 characters minimum</li>
                      </ul>
                    </div>
                  </div>
                </b-form-group>
              </div>
              <div class="form-input">
                <b-form-group>
                  <b-form-input
                    id="password-register-v3"
                    v-on:keyup="validatePassword()"
                    v-model.trim="$v.valueConfirmPassword.$model"
                    :type="confirmPassViewState"
                    class="form-control input-box-v3"
                    :class="{'is-invalid': validationStatus($v.valueConfirmPassword)}"
                    placeholder="Confirm password"></b-form-input>
                    <div class="invalid-feedback">
                      <span v-if="!$v.valueConfirmPassword.sameAsPassword" id="password-error" class="required-color error-message-v3">Password doesn’t match. Please double-check the password and try  again.</span>
                    </div>
                  <span v-if="expiredMsg == true" id="password-error" class="mt-2 required-color error-message-v3">Password reset expired. Please reset your password again.</span>
                  <img v-if="confirmPassViewState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showConfirmPassword()">
                  <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showConfirmPassword()">
                </b-form-group>
              </div>
            <button class="forgot-password-btn-v3" :disabled="$v.$invalid || expiredMsg == true">
              <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
              Submit
            </button>
            <span v-if="expiredMsg == true" @click="toResetPage()" class="d-flex justify-content-center text-align-items-center mt-3 cursor-pointer expired-btn-reset">Reset Password</span>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import { required, sameAs, maxLength, minLength } from 'vuelidate/lib/validators'
import { authMethods } from "@/state/helpers";

export default {
  name: 'ChangePassword',
  components: {
    Navbar
  },
  data() {
    return {
      password: '',
      valueConfirmPassword: '',
      passwordState: 'password',
      confirmPassViewState: 'password',
      loading: false,
      authError: null,
      isAuthError: false,
      tryingToChangePassword: false,
      expiredMsg: false,
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(128),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        const containsSpecial = /[!#@~*&^%)([\]\\+=?<>;:"`$_)-/',.|}{]+/.test(value)
        return containsUppercase && containsLowercase && containsNumber && containsSpecial
      }
    },
    valueConfirmPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  computed : {
    hash(){
      return this.$route.query.hash
    }
  },
  methods: {
    ...authMethods,
    toResetPage() {
      this.$router.push('/forgot-password')
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    showPassword() {
      if(this.passwordState === 'password'){
        this.passwordState = 'text'
      } else {
        this.passwordState = 'password'
      }
    },
    showConfirmPassword() {
      if(this.confirmPassViewState === 'password'){
        this.confirmPassViewState = 'text'
      } else {
        this.confirmPassViewState = 'password'
      }
    },
    validatePassword() {
      var lower = document.getElementById("lower-char")
      var upper = document.getElementById("upper-char")
      var num = document.getElementById("number-char")
      var special = document.getElementById("special-char")
      var minChar = document.getElementById("minimum-character")

      if(this.password.match(/[a-z]/)){
        lower.style.color = '#262A2C'
        lower.style.setProperty('--before-char', '#009934')
      } else {
        lower.style.color = '#919394'
        lower.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.match(/[A-Z]/)){
        upper.style.color = '#262A2C'
        upper.style.setProperty('--before-char', '#009934')
      } else {
        upper.style.color = '#919394'
        upper.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.match(/[0-9]/)){
        num.style.color = '#262A2C'
        num.style.setProperty('--before-char', '#009934')
      } else {
        num.style.color = '#919394'
        num.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.match(/[!#@~*&^%)([\]\\+=?<>;:"`$_)-/',.|}{]+/)){
        special.style.color = '#262A2C'
        special.style.setProperty('--before-char', '#009934')
      } else {
        special.style.color = '#919394'
        special.style.setProperty('--before-char', '#E9EAEA')
      }

      if(this.password.length >= 8){
        minChar.style.color = '#262A2C'
        minChar.style.setProperty('--before-character', '#009934')
      } else {
        minChar.style.color = '#919394'
        minChar.style.setProperty('--before-character', '#E9EAEA')
      }
    },
    tryToChangePassword() {
      this.loading = true;
      this.tryingToChangePassword = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.changePassword({
          password: this.password,
          hash : this.hash
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToChangePassword = false;
            this.isAuthError = false;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push('/success-change-password');
          })
          .catch(error => {
            this.tryingToChangePassword = false;
            this.authError = error ? error : "";
            this.isAuthError = true;
            this.loading = false;
            if(error.data.status == 400){
              this.expiredMsg = true
              // this.expiredMsg = typeof error == 'object' ? error.data.display_message : "";
            }
          })
      );
    }
  }
}
</script>

<style>

</style>
